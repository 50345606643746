<template>
  <ion-page class="page">
    <div>
      <social-space-header
        v-if="!isEmpty(socialspace)"
        :socialspace="socialspace"
        :status="status"
        :is-manage="true"
        :show-back="true"
        @reaction="onReaction"
      />
      <div class="wrapper py-2 px-4 bg-transparent-dark">
        <div class="secion mt-4">
          <div class="title mt-4 mb-2">Requests To Join</div>
          <p v-if="!get(userRequests, 'length')">No pending requests.</p>
          <div v-for="(user, index) in userRequests" :key="index">
            <div class="d-flex align-items-center">
              <router-link
                :class="{
                  'text-color-username':
                    usernameColor(user.requestee) && !usernameColor(user.requestee).startsWith('#'),
                  'bold-username': usernameColor(user.requestee),
                }"
                :style="
                  !usernameColor(user.requestee)
                    ? ''
                    : usernameColor(user.requestee).startsWith('#')
                    ? `color: ${usernameColor(user.requestee)} !important`
                    : `background-image: ${usernameColor(user.requestee)} !important`
                "
                :to="{
                  name: 'profile',
                  params: { username: user.requestee.username },
                }"
              >
                <div class="name">{{ user.requestee.username }}</div>
              </router-link>
              <div class="ml-auto">
                <ion-button :disabled="isUserReqUpdating" class="approve-btn" @click="approveUserRequest(user.id)">
                  <ChLoading size="sm" v-if="updatingUserReq === user.id" class="spinner" />
                  <span v-else>Accept</span>
                </ion-button>
                <ion-button
                  color="dark"
                  class="ml-2 approve-btn"
                  :disabled="isUserReqUpdating"
                  @click="declineUserRequest(user.id)"
                >
                  <ChLoading size="sm" v-if="updatingUserReq === user.id" class="spinner" />
                  <span v-else>Decline</span>
                </ion-button>
              </div>
            </div>
            <div class="description mt-1">
              <i class="msg-inner">"{{ user.message }}"</i>
            </div>
          </div>
        </div>

        <div class="section mt-4">
          <div class="title">Chatroom Requests</div>
          <p v-if="!get(chatRequests, 'length')">No pending requests.</p>

          <div v-for="(chat, index) in chatRequests" :key="index" class="mt-3 px-2">
            <div class="d-flex align-items-center">
              <router-link
                :class="{
                  'text-color-username':
                    usernameColor(chat.requestee) && !usernameColor(chat.requestee).startsWith('#'),
                  'bold-username': usernameColor(chat.requestee),
                }"
                :style="
                  !usernameColor(chat.requestee)
                    ? ''
                    : usernameColor(chat.requestee).startsWith('#')
                    ? `color: ${usernameColor(chat.requestee)} !important`
                    : `background-image: ${usernameColor(chat.requestee)} !important`
                "
                :to="{
                  name: 'profile',
                  params: { username: chat.requestee.username },
                }"
              >
                <div class="name">{{ chat.requestee.username }}</div>
              </router-link>
              <div class="ml-auto">
                <ion-button :disabled="isChatReqUpdating" class="approve-btn" @click="approveChatRequest(chat.id)">
                  <ChLoading size="sm" v-if="updatingChatReq === chat.id" class="spinner" />
                  <span v-else>Accept</span>
                </ion-button>
                <ion-button
                  color="dark"
                  class="ml-2 approve-btn"
                  :disabled="isChatReqUpdating"
                  @click="declinechatRequest(chat.id)"
                >
                  <ChLoading size="sm" v-if="updatingChatReq === chat.id" class="spinner" />
                  <span v-else>Decline</span>
                </ion-button>
              </div>
            </div>
            <div class="description mt-1">
              <i class="msg-inner">"{{ chat.message }}"</i>
            </div>
          </div>
        </div>

        <div class="section mt-4">
          <div class="title">Character Requests</div>
          <p v-if="!get(charRequests, 'length')">No pending requests.</p>

          <div v-for="(char, index) in charRequests" :key="index" class="mt-3 px-2">
            <div class="world-card-small d-flex">
              <img loading="lazy" v-image :src="resizeUpload(char.requestee.info.cropProfilePicture, '80x80')" />

              <div class="rest-info ml-3 flex-grow-1 world-info">
                <div class="name d-flex align-items-center justify-content-start">
                  <router-link
                    :to="{
                      name: 'character-profile-new',
                      params: { slug: char.requestee.slug },
                    }"
                    class="text-black clickable-item-hov"
                    >{{ char.requestee.info.name }}</router-link
                  >
                  <p class="small-name ml-1 mb-2">
                    by
                    <router-link
                      :class="{
                        'text-color-username':
                          usernameColor(char.requestee) && !usernameColor(char.requestee).startsWith('#'),
                        'bold-username': usernameColor(char.requestee),
                      }"
                      :style="
                        !usernameColor(char.requestee)
                          ? ''
                          : usernameColor(char.requestee).startsWith('#')
                          ? `color: ${usernameColor(char.requestee)} !important`
                          : `background-image: ${usernameColor(char.requestee)} !important`
                      "
                      :to="{
                        name: 'profile',
                        params: { username: char.requestee.user },
                      }"
                      class="clickable-item-hov"
                      >{{ char.requestee.user }}</router-link
                    >
                  </p>
                </div>

                <div class="description mt-2">
                  {{ truncateText(stripHtmlTags(char.requestee.info.description), 120) }}
                </div>
                <div class="actions mt-2 w-75 d-flex justify-content-start">
                  <ion-button :disabled="isCharReqUpdating" class="approve-btn" @click="approveCharRequest(char.id)">
                    <ChLoading size="sm" v-if="updatingCharReq === char.id" class="spinner" />
                    <span v-else>Accept</span>
                  </ion-button>
                  <ion-button
                    color="dark"
                    class="ml-2 approve-btn"
                    :disabled="isCharReqUpdating"
                    @click="declinecharRequest(char.id)"
                  >
                    <ChLoading size="sm" v-if="updatingCharReq === char.id" class="spinner" />
                    <span v-else>Decline</span>
                  </ion-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section mt-4">
          <div class="title">Team</div>
          <div class="note mt-3 mb-2">
            If you'd like to add another World Leader to your world, please email
            <a href="mailto:sydney@characterhub.com" class="text-black clickable-item-hov">sydney@characterhub.com</a>
            from the email on your account with the username you'd like to add.
          </div>
        </div>

        <div class="section my-4">
          <div class="title">Manage Members ({{ get(worldMembers, 'length', 0) }})</div>

          <div class="note my-3 mb-2">
            Need to remove a character or member? Email
            <a href="mailto:sydney@characterhub.com" class="text-black clickable-item-hov">sydney@characterhub.com</a>
            with the character or the member.
          </div>

          <div v-for="(member, index) in worldMembers" :key="index">
            <div class="d-flex align-items-center">
              <router-link
                :class="{
                  'text-color-username': usernameColor(member.user) && !usernameColor(member.user).startsWith('#'),
                  'bold-username': usernameColor(member.user),
                }"
                :style="
                  !usernameColor(member.user)
                    ? ''
                    : usernameColor(member.user).startsWith('#')
                    ? `color: ${usernameColor(member.user)} !important`
                    : `background-image: ${usernameColor(member.user)} !important`
                "
                :to="{
                  name: 'profile',
                  params: { username: member.user.username },
                }"
                class="clickable-item-hov"
              >
                {{ member.user.username }}
                <!-- <span v-if="member.role === worldMemberRoles.leader">({{ worldMemberRoleTitles[member.role] }})</span> -->
              </router-link>
              <div v-if="loggedInUser.id !== member.user.id" class="ml-auto d-flex">
                <a
                  href="#"
                  class="text-primary d-flex align-items-center mr-3"
                  @click.prevent="updateMemberRole(member.id, member.user.username, member.role)"
                >
                  <ion-icon :icon="personCircleOutline" color="primary" class="mr-1" />
                  <span v-if="member.role == 'member'">Make leader</span>
                  <span v-else-if="member.role == 'leader'">Make member</span>
                </a>
                <a
                  href="#"
                  class="text-danger d-flex align-items-center"
                  @click.prevent="removeMember(member.id, member.user.username)"
                >
                  <ion-icon :icon="closeCircleOutline" color="danger" class="mr-1" />
                  <span>Remove</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 d-flex justify-content-center align-items-center flex-wrap">
          <ion-button class="action ml-2" color="danger" @click="deleteSocialSpace()"> Delete Social Space </ion-button>
        </div>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import SocialSpaceHeader from './SocialSpaceHeader.vue';
import { stripHtmlTags } from '@/shared/utils/string';
import { SocialSpace } from '@/shared/types/static-types';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';
import {
  approveWorldRequest,
  declineWorldRequest,
  getWorld,
  getWorldMembers,
  getWorldUserCharacterRequest,
  removeWorldMember,
  deleteWorld,
  updateWorldMemberRole,
  getMemberstatus,
} from '@/shared/actions/worlds';
import { toast } from '@/shared/native';
import store from '@/shared/store';
import { approveRequestToJoinSocialChatroom } from '@/shared/actions/socialSpaceChatroom';
import { authStore } from '@/shared/pinia-store/auth';

const userRequests = ref([] as any[]);
const chatRequests = ref([] as any[]);
const worldMembers = ref([] as any[]);
const charRequests = ref([] as any[]);
const status = ref({});
const socialspace = ref({}) as Ref<SocialSpace>;
const route = useRoute();
const { user: loggedInUser } = authStore();

// public worldMemberRoles = constants.worldMemberRoles;
// public worldMemberRoleTitles = constants.worldMemberRoleTitles;

const isUserReqUpdating = ref(false);
const isChatReqUpdating = ref(false);
const updatingChatReq = ref('');
const updatingUserReq = ref('');
const isCharReqUpdating = ref(false);
const updatingCharReq = ref('');

const fetchUserRequest = async () => {
  try {
    let response;

    response = await getWorldUserCharacterRequest(route.params.id as string);

    userRequests.value = response;
  } catch (e) {
    await toast.show('Could not load userlisting. Please try again.', 'nonative', 'danger');
  }
};

const fetchChatroomRequests = async () => {
  try {
    let response;
    response = await getWorldUserCharacterRequest(route.params.id as string, {
      is_rooms: false,
    });
    chatRequests.value = response;
  } catch (e) {
    await toast.show('Could not load chatroomlisting. Please try again.', 'nonative', 'danger');
  }
};

const usernameColor = (user: any) => {
  return user?.customize_profile?.username_color || '';
};

const fetchWorldMembers = async () => {
  try {
    let response;
    response = await getWorldMembers({ world_id: route.params.id as string });

    worldMembers.value = response;
  } catch (e) {
    await toast.show('Could not load userlisting. Please try again.', 'nonative', 'danger');
  }
};
const fetchWorldMemberStatus = async () => {
  const response = await getMemberstatus({
    world_id: route.params.id as string,
  });
  status.value = response;
};

const approveCharRequest = async (id: string) => {
  if (isCharReqUpdating.value) return;

  isCharReqUpdating.value = true;
  updatingCharReq.value = id;
  try {
    await approveWorldRequest({
      id,
      world: route.params.id as string,
    });
    charRequests.value = charRequests.value.filter((char) => char.id !== id);
    await toast.show('Request approved', 'nonative', 'success');
  } catch (e) {
    await toast.show('Could not approve this character. Please try again.', 'nonative', 'danger');
  }
  updatingCharReq.value = '';
  isCharReqUpdating.value = false;
};

const updateMemberRole = async (id: string, name: string, currentrole: string) => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to make  <b>${name}</b> a <b>${
        currentrole === 'member' ? 'leader' : 'member'
      }</b>.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-primary',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      await updateWorldMemberRole(id, currentrole === 'member' ? 'leader' : 'member');
      fetchWorldMembers();
      toast.show('Role updated successfully', 'nonative', 'success');
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};

const removeMember = async (id: string, name: string) => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to remove <b>${name}</b> from this world.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      await removeWorldMember(id);
      fetchWorldMembers();
      toast.show('Member removed successfully', 'nonative', 'success');
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};
const onReaction = (event: { reactResp: {}; social: SocialSpace }) => {
  socialspace.value = {
    ...event.social,
    ...get(event.reactResp, 'updatedReactionsData', {}),
  };
};

const approveUserRequest = async (id: string) => {
  if (isUserReqUpdating.value) return;

  isUserReqUpdating.value = true;
  updatingUserReq.value = id;
  try {
    await approveWorldRequest({
      id,
      world: route.params.id as string,
    });
    userRequests.value = userRequests.value.filter((user) => user.id !== id);
    await toast.show('Request approved', 'nonative', 'success');
    fetchWorldMembers();
  } catch (e) {
    await toast.show('Could not approve this user. Please try again.', 'nonative', 'danger');
  }

  updatingUserReq.value = '';
  isUserReqUpdating.value = false;
};

const declinecharRequest = async (id: string) => {
  await declineWorldRequest(id);
  charRequests.value = charRequests.value.filter((char) => char.id !== id);
};

const fetchCharacterRequest = async () => {
  try {
    let response;
    response = await getWorldUserCharacterRequest(route.params.id as string, {
      requestee_type: 'character',
    });

    charRequests.value = response;
  } catch (e) {
    await toast.show('Could not load userlisting. Please try again.', 'nonative', 'danger');
  }
};

const deleteSocialSpace = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to delete this world. You will not be able to recover it later.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    const router = useRouter();
    if (role === 'ok') {
      await deleteWorld(route.params.id as string);
      toast.show('World deleted successfully', 'nonative', 'success');
      router.push({ name: 'worlds' });
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};

const approveChatRequest = async (id: string) => {
  if (isChatReqUpdating.value) return;

  isChatReqUpdating.value = true;
  updatingChatReq.value = id;
  try {
    await approveRequestToJoinSocialChatroom(id, route.params.id as string);
    chatRequests.value = chatRequests.value.filter((char) => char.id !== id);
    await toast.show('Request approved', 'nonative', 'success');
  } catch (e) {
    await toast.show('Could not approve this chatroom. Please try again.', 'nonative', 'danger');
  }
  updatingChatReq.value = '';
  isChatReqUpdating.value = false;
};

const declineUserRequest = async (id: string) => {
  await declineWorldRequest(id);
  userRequests.value = userRequests.value.filter((user) => user.id !== id);
};

const declinechatRequest = async (id: string) => {
  await declineWorldRequest(id);
  chatRequests.value = chatRequests.value.filter((char) => char.id !== id);
};

const fetchWorld = async () => {
  socialspace.value = await getWorld(route.params.id as string);
};

onMounted(async () => {
  try {
    await fetchWorld();
    fetchUserRequest();
    fetchChatroomRequests();
    fetchWorldMembers();
    fetchWorldMemberStatus();
    fetchCharacterRequest();
  } catch (_e) {
    // router.push({ name: 'worlds' });
  }
});
</script>

<style lang="sass" scoped>
.title
  font-size: 24px
  font-weight: bold
.wrapper
  background-color: #FFF
  width: 100%

.note
  font-size: 16px

@media(max-width: 500px)
  .wrapper
    padding: 0 15px
    position: absolute

.title
  font-size: 24px
  font-weight: bold
.username
  font-size: 16px
  font-weight: bold

.name
  font-size: 18px
  font-weight: bold

.actions
  max-width: 200px
  .inline-button
    &:not(:first-of-type)
      margin: 0 5px

.world-card-small
  img
    width: 76px
    height: 76px
    border-radius: 40px
    border: solid gray 0.1px
    object-fit: cover
  .name
    font-size: 20px
    font-weight: bold

  .small-name
    font-size: 12px
  .description
    font-size: 14px
  .icon-button
    color: #ae38e5
    font-size: 20px
    .d-flex
      font-size: 20px
      color: #3dc2ff
  .reaction-count
    font-size: 18px
    font-weight: bold

.routerlink
  color: #214163

.world-info
  width: calc(100% - 93px)

.approve-btn
  width: 100px
  .spinner
    width: 15px
    height: 15px
.msg-inner
  white-space: pre-line
</style>
